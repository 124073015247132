import {get,post} from "../axios";
export function queryAccountCard(url,params){//分页查询账号信息
    return get(url,params)
}
export  function generateAccountCard(url,params){
    return get(url,params)
}
export function delAccountCardByKey(url,params){//删除指定卡密
    return get(url,params)
}
export function delAccountCardsByKeys(url,params){//删除多个卡密
    return get(url,params)
}
export function editAccountCardByKey(url,params){//删除指定卡密
    return post(url,params)
}
export  function queryAccountCardByKey(url,params){
    return get(url,params)
}
export  function  updateAccountCardStatus(url,params){
    return get(url,params)
}

