import {get,post} from "../axios";
export function queryGoodsStandards(url,params){//分页查询规格信息
    return get(url,params)
}
export function queryAllGoodsStandards(url,params){//查询所有商品信息
    return get(url,params)
}
export function deleteGoodsStandardsByIds(url,params){//通过id删除指定规格信息
    return get(url,params)
}
export function saveGoodsStandards(url,params){//新增规格信息
    return post(url,params)
}
