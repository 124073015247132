import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'vant/lib/icon/local.css';
import api from "@/http/api";
import router from "./router/router"
import { Icon } from 'vant';
import { Tab, Tabs } from 'vant';
import { Grid, GridItem } from 'vant';
import { Search } from 'vant';
import { Card } from 'vant';
import { ActionSheet } from 'vant';
import { Image as VanImage } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Loading } from 'vant';
import Qrcode from '@chenfengyuan/vue-qrcode';
import { Toast } from 'vant';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Row, Col } from 'vant';
import { SwipeCell } from 'vant';
import { Sku } from 'vant';
import { TreeSelect } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';

// Vue.use(Toast);
Vue.use(Checkbox).use(CheckboxGroup);
Vue.use(Tab).use(Tabs);
Vue.use(TreeSelect);
Vue.use(Sku);
Vue.use(SwipeCell);
Vue.use(Row).use(Col);
Vue.use(ElementUI);
Vue.use(Toast);
Vue.use(Qrcode)
Vue.use(Form);
Vue.use(Field);
Vue.use(VanImage);
Vue.use(ActionSheet);
Vue.use(Card)
Vue.use(Search);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(Icon)
Vue.use(Loading);
Vue.prototype.$api = api
new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
