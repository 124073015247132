import {get,post} from "../axios";
export function queryAccount(url,params){//分页查询账号信息
    return get(url,params)
}
export  function addAccount(url,params){
    return post(url,params)
}
export function delAccountByKey(url,params){//通过邮箱删除指定账号
    return get(url,params)
}
export function delAccountsByKeys(url,params){//通过邮箱删除指定账号
    return get(url,params)
}
export  function updatePassword(url,params){
    return post(url,params)
}
export  function updateAccount(url,params){
    return post(url,params)
}
