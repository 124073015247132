import {get,post} from "../axios";
// import * as url from "url";
export function querySellAbleGoodsGroup(url,params){//分页查询可售卖 分类信息
    return get(url,params)
}
export function queryAllGoodsGroup(url,params){//查询所有商品信息
    return get(url,params)
}
export function deleteGoodsGroupByIds(url,params){//通过id删除指定分类信息
    return get(url,params)
}
export function saveGoodsGroup(url,params){//新增分类信息
    return post(url,params)
}

export function queryPageGroup(url,params){
    return get(url,params)
}

export function queryGoodsByGroupId(url,params){
    return get(url,params)
}

export function queryGroupByParentId(url,params){
    return get(url,params)
}

export function queryTopGroup(url,params){
    return get(url,params)
}

export function querySubGroupByLevel1Id(url,params){
    return get(url,params)
}