import {get,post} from "../axios";
export function queryGoods(url,params){//分页查询商品信息
        return get(url,params)
}
export function deleteGoodsByIds(url,params){//通过id删除指定商品信息
        return get(url,params)
}
export function updateGoodsById(url,params){//通过id修改指定商品信息
        return post(url,params)
}

export function queryGoodsByGroupId(url,params){
        return get(url,params)
}