import * as user from "@/http/modules/user";
import * as goods from "@/http/modules/goods";
import * as group from "@/http/modules/group";
import * as goodsStandards from "@/http/modules/goodsStandards";
import * as data from "@/http/modules/data"
import * as order from "@/http/modules/order";
import * as account from "@/http/modules/account"
import * as accountCard from "@/http/modules/accountCard"
import * as loginLog from  "@/http/modules/loginLog"
import * as wxpay from "@/http/modules/wxpay"
import * as sku from "@/http/modules/sku"
import * as skuOrder from "@/http/modules/skuOrder"
import * as shoppingCart from "@/http/modules/shoppingCart"
export default {
    //封装
    user,
    goods,
    goodsStandards,
    data,
    order,
    account,
    accountCard,
    loginLog,
    group,
    wxpay,
    sku,
    skuOrder,
    shoppingCart
}