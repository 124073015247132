import Vue from 'vue'
import VueRouter from "vue-router"
Vue.use(VueRouter)
//创建路由表
const routes=[
    {
        path:'/',
        components: {main:() => import ("@/components/Login")}
    },
    {
        path:'/Home',
        components: {main:() => import ("@/components/Home")},
        children:[
            // {
            //     path:'/IndexPage',
            //     components: {content:() => import ("@/components/Index")},
            // },OrderPage
            {
                path:'/ClassificationPage',
                components: {content:() => import ("@/components/classification")},
            },
            {
                path:'/OrderPage/:topUpAccount', // 下单页面
                components: {content:() => import ("@/components/OrderPage")},
            },
            {
                path:'/GoodsDetail/:goodsid',
                components: {content:() => import ("@/components/GoodsDetail")},
            },
            {
                path:'/GoodsPage/:groupid',
                components: {content:() => import ("@/components/Goods")},
            },
            {
                path:'/MyOrder',
                components: {content:() => import ("@/components/MyOrder")},
            },
            {
                path:'/PersonCenter',
                components: {content:() => import ("@/components/PersonCenter")},
            },
            {
                path: '/ShoppingCart',
                components: {content: () => import("@/components/ShoppingCart")}
            },
            {
                path: '/OrderConfirm',
                components: {content: () => import("@/components/OrderConfirm")}
            }
            // {
            //     path: '/QRCodeGenerator/:codeUrl',
            //     components: {content:() => import("@/components/QRCodeGenerator")}
            // }
        ]
    }
]

//创建路由实例--相当于路由器
const router= new  VueRouter({
    routes,
    mode:'hash',
    linkActiveClass:'active'
})
export default router
