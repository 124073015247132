import axios from "axios";
import config from "@/http/config";
const instance=axios.create({
    baseURL: config.baseURL,
    timeout: config.timeout,
    headers: config.headers
})

instance.interceptors.request.use(
    config=>{
        let token=localStorage.getItem("token");
        // console.log("token信息：",token);
        if(token){
            config.headers['Authorization'] = `Bearer ${token}`;
            // instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            // config.headers.Authorization=`Bearer ${token}` // 必须加Bearer，否则后端拿不到
            // alert("带上请求数据token（非登录）: ")
            // alert(config.headers.Authorization)
        }
        return config
    },
    err=>{
        return Promise.reject(err)
    },
)
instance.interceptors.response.use(
    responce=>{
        // if(responce.data.data!=null){
        //     return responce.data.data;
        // }else{
        //     return responce.data;
        // }
        const token = responce.headers.get("Authorization");
        if(token){
            localStorage.setItem("token",token) // 保存token
            // alert("登录响应token")
            // alert(token)
            // alert("保存token")
        }
        return responce.data;
    },err=>{
        console.log(err);
    }
)
export function get(url,params){
    return instance.get(url,{params})
}
export function post(url,params){
    return instance.post(url,params)
}